<template>
    <div class="catalogues__list">
        <button class="catalogues__list__button" v-b-modal.list-catalogue-1>
            Listado de Catálogos
        </button>
        <b-modal id="list-catalogue-1" title="Listado de Catálogos" ok-only scrollable>
            <div class="my-4" v-for="catalogue in getAllCatalogues" :key="catalogue.id" target="_blank">
                <a :href="catalogue.file">{{ catalogue.title }}</a>
                <b-icon @click="deleteCatalogueHelper(catalogue.id, catalogue.file)" icon="x"
                    :style="{ color: 'red', cursor: 'pointer' }"></b-icon>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    methods: {
        async deleteCatalogueHelper(id, file) {
            try {
                await this.deleteCatalogue({
                    catalogueId: id,
                    fileUrl: file,
                });
                this.$toast.success("Se elemino correctamente");
            } catch (error) {
                this.$toast.error("Ocurrio un error al borrar el Catálogo");
            }
        },
        ...mapActions("vCard", ["deleteCatalogue"]),
    },
    computed: {
        ...mapGetters("vCard", ["getAllCatalogues"]),
    },
};
</script>

<style>
</style>
